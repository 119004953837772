import { Button } from "@/components/ui/button";
import { ButtonLoader } from "@/components/ButtonLoader";
import { CalendarMinus2 as CalendarMinus2Icon, CalendarX as CalendarXIcon } from "lucide-react";
import { type Group } from "./types";

type ControlPanelProps = {
    isLoadingDepartments: boolean;
    selectedGroups: string;
    setSelectedGroupsHandler: (groups: string | undefined | Group[]) => void
    calendarZoomLevel: number;
    setCalendarZoomLevel: React.Dispatch<React.SetStateAction<number>>;
    showFiltersDialogHandler: () => void;
    isHodActive: boolean;
    onHodClick: () => void;
};

export const ControlPanel = ({
    isLoadingDepartments,
    selectedGroups,
    setSelectedGroupsHandler,
    calendarZoomLevel,
    setCalendarZoomLevel,
    showFiltersDialogHandler,
    isHodActive,
    onHodClick,
}: ControlPanelProps) => {
    return (
        <div id="ship-calendar-filters" className="flex justify-between">
            <div className="flex">
                <div className="pe-3 w-52">
                    <Button disabled={isLoadingDepartments} className={`w-full h-12 ${ selectedGroups === 'all' ? 'bg-orange-300 hover:bg-orange-400' : '' }`} variant='outline' onClick={() => setSelectedGroupsHandler('all')}>
                        { isLoadingDepartments 
                            ? (<ButtonLoader isLoading={true} />) 
                            : "All crew"
                        }
                    </Button>
                </div>
                <div className="pe-3 w-52">
                    <Button disabled={isLoadingDepartments} className={`w-full h-12 ${ isHodActive ? 'bg-orange-300' : '' }`} variant='outline' onClick={onHodClick}>
                        { isLoadingDepartments 
                            ? (<ButtonLoader isLoading={true} />) 
                            : "HODs"
                        }
                    </Button>
                </div>
            </div>

            <div className="flex"> 
                <div className="grow max-w-52 pe-3">
                    <Button disabled={isLoadingDepartments} className="w-full h-12 flex" variant='outline' onClick={() => setCalendarZoomLevel((prev: number) => prev === 1 ? 2 : 1)}>
                        { calendarZoomLevel === 1
                            ? (<CalendarXIcon />)
                            : (<CalendarMinus2Icon />)
                        }
                    </Button>
                </div>

                <div className="grow max-w-52">
                    <Button id="ship-calendar-filters-open-button" disabled={isLoadingDepartments} className="w-full h-12 flex" variant='outline' onClick={showFiltersDialogHandler}>
                        { isLoadingDepartments 
                            ? (<ButtonLoader isLoading={true} />) 
                            : "Filters"
                        }
                    </Button>
                </div>
            </div>
        </div>
    );
}
